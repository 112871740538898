import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import './styles.css'

function Contact() {
    const [textShadow, setTextShadow] = useState({
        primaryTextX: 0,
        primaryTextY: 0,
        secondaryTextX: 0,
        secondaryTextY: 0
    })
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const formSchema = yup.object().shape({
        full_name: yup.string().required("Full name required"),
        email: yup.string().email("Invalid email").required("Email required"),
        subject: yup.string().required("Subject required"),
        message: yup.string().required("Message required"),
    });

    const formik = useFormik({
        initialValues: {
            full_name: "",
            email: "",
            subject: "",
            message: "",
        },
        validationSchema: formSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(values)
            try {
                const response = await fetch(
                    "https://app.christianhernandez.ca/send-email",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(values),
                    }
                );

                if (response.ok) {
                    // Handle success (e.g., show a success message)
                    setSuccessMessage(
                        "Email sent successfully, we will reply back you you soon."
                    );

                    setTimeout(() => {
                        setSuccessMessage("");
                    }, 2500);
                } else {
                    // Handle errors (e.g., show an error message)
                    setErrorMessage("Error sending email, please try again.");

                    setTimeout(() => {
                        setErrorMessage("");
                    }, 2500);
                }
            } catch (error) {
                // Handle fetch request errors
                console.error("Error sending email:", error);
            }

            // Optionally, reset the form after successful submission
            resetForm();
        },
    });

    useEffect(() => {

        setTimeout(() => {
            setTextShadow({
                primaryTextX: -8,
                primaryTextY: -6,
                secondaryTextX: 8,
                secondaryTextY: 6
            })
        }, 500);

    }, [])

    return (
        <section className='Contact'>
            <div className='contact-wrapper'>
                <div className='contact-left'>
                    <div className='title-overlay'>
                        <h1 style={{
                            textShadow: `${textShadow.primaryTextX}px ${textShadow.primaryTextY}px 0px #D78312`
                        }}>
                            SEND US A <br />
                            MESSAGE
                        </h1>
                        <h1 style={{
                            textShadow: `${textShadow.secondaryTextX}px ${textShadow.secondaryTextY}px 0px #0F2934`
                        }}>
                            SEND US A  <br />
                            MESSAGE
                        </h1>
                    </div>
                    <p>Begin your business improvement journey with us</p>
                </div>
                <div className='contact-right'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='form-wrapper'>
                            <h2>Contact Us</h2>
                            <input
                                style={{ marginTop: "1.5rem" }}
                                type="text"
                                name="full_name"
                                placeholder="Full Name*"
                                onChange={formik.handleChange}
                                value={formik.values.full_name}
                            />
                            {formik.touched.full_name && formik.errors.full_name ? (
                                <div className="error">{formik.errors.full_name}</div>
                            ) : null}
                            <input
                                type="email"
                                name="email"
                                placeholder="Email*"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="error">{formik.errors.email}</div>
                            ) : null}
                            <input
                                type="text"
                                name="subject"
                                placeholder="Subject*"
                                onChange={formik.handleChange}
                                value={formik.values.subject}
                            />
                            {formik.touched.subject && formik.errors.subject ? (
                                <div className="error">{formik.errors.subject}</div>
                            ) : null}
                            <textarea
                                style={{ margin: successMessage || errorMessage ? ".5rem 0 .5rem 0" : ".5rem 0 1rem 0" }}
                                name="message"
                                placeholder="Message*"
                                onChange={formik.handleChange}
                                value={formik.values.message}
                                rows="8"
                            ></textarea>
                            {formik.touched.message && formik.errors.message ? (
                                <div className="error">{formik.errors.message}</div>
                            ) : null}
                            <div className='success-message' style={{ margin: successMessage || errorMessage ? "0 0 .5rem 0" : "0", color: setErrorMessage ? "green" : "red" }}>
                                {successMessage || errorMessage && (
                                    <>
                                        <p>{successMessage}</p>
                                        <p>{errorMessage}</p>
                                    </>
                                )}
                            </div>
                            <button type='submit'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Contact