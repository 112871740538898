import './styles.css'
import React, { useEffect } from 'react'
import Hero from '../../components/Hero/Hero';
import InfiniteCarousel from '../../components/InfiniteCarousel/InfiniteCarousel';
import About from './sections/About/About';
import Newsletter from '../../components/Newsletter/Newsletter';
import Video from './sections/Video/Video';
import Team from './sections/Team/Team';
import Testimonials from './sections/Testimonials/Testimonials';
import Contact from '../../components/Contact/Contact';
import services from '../../assets/services.png'
import desktopMockup from '../../assets/desktop-mockup.png'

function Home({ fadeIn, setFadeIn }) {

    useEffect(() => {
        setTimeout(() => {
            setFadeIn(prevState => ({
                ...prevState,
                home: true
            }));
        }, 300);
    }, [])

    const carousel = [
        { img: services },
        { img: services },
        { img: services },
        { img: services }
    ]

    return (
        <div className='Home' style={{ opacity: fadeIn.home ? "1" : "0" }}>
            <Hero
                desktopMockup={desktopMockup}
                statement="OUR VISION"
                mainText="CREATIVE"
                subText="DESIGNS"
                paragraph="We are a digital marketing and website design agency.
                Based in Southern California, dedicated to
                turning your online aspirations into realities."
                button="Learn More"
            />
            <InfiniteCarousel carousel={carousel} />
            <About />
            <Newsletter />
            <Video />
            <Team />
            <Testimonials />
            <Contact />
        </div>
    )
}

export default Home