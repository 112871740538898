import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import './styles.css'

function Newsletter() {
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const formSchema = yup.object().shape({
        email: yup.string().email().required('Email required'),
        name: yup.string().required('First name required'),
        last_name: yup.string().required('Last name required')
    })

    const formik = useFormik({
        initialValues: {
            email: "",
            name: "",
            last_name: "",
        },
        validationSchema: formSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(values)
            try {
                const response = await fetch(
                    "https://app.christianhernandez.ca/subscriber",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(values),
                    }
                );

                if (response.ok) {
                    // Handle success (e.g., show a success message)
                    setSuccessMessage(
                        "Thank you for subscribing to our newsletter! 🎉 We're thrilled to have you on board."
                    );

                    setTimeout(() => {
                        setSuccessMessage("");
                    }, 2500);
                } else {
                    // Handle errors (e.g., show an error message)
                    setErrorMessage("There has been an error subscribing to our newsletter, please try again.");

                    setTimeout(() => {
                        setErrorMessage("");
                    }, 2500);
                }
            } catch (error) {
                // Handle fetch request errors
                console.error("Error sending email:", error);
            }

            // Optionally, reset the form after successful submission
            resetForm();
        }
    })

    return (
        <section className='Newsletter'>
            <h3>Subscribe to our Newsletter</h3>
            <form onSubmit={formik.handleSubmit}>
                <input
                    type='email'
                    name='email'
                    placeholder='Email'
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />
                <input
                    type='text'
                    name='name'
                    placeholder='First Name'
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
                <input
                    type='text'
                    name='last_name'
                    placeholder='Last Name'
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                />
                <button type='submit'>Subscribe</button>
            </form>
            <div className='success-message' style={{ margin: successMessage || errorMessage ? "0 .5rem" : "0" }}>
                {successMessage || errorMessage && (
                    <>
                        <p>{successMessage}</p>
                        <p>{errorMessage}</p>
                    </>
                )}

            </div>
        </section>
    )
}

export default Newsletter