import React from 'react'
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare, FaYelp, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import logoFont from '../../assets/logo-font.png'
import './styles.css'

function Footer() {
    return (
        <section className='Footer'>
            <div className='footer-wrapper'>
                <div className='footer-left'>
                    <h3>Connect</h3>
                    <hr />
                    <ul>
                        <li><AiFillInstagram /></li>
                        <li><FaFacebookSquare /></li>
                        <il><FaYelp /></il>
                        <li><FaLinkedin /></li>
                        <li><FaXTwitter /></li>
                    </ul>
                    <img src={logoFont} width={100} alt='logo' />
                </div>
                <div className='middle-left'>
                    <h3>Services</h3>
                    <hr />
                    <ul>
                        <li>
                            <p>Tailored Solutions</p>
                        </li>
                        <li>
                            <p>Local Expertise</p>
                        </li>
                        <li>
                            <p>Budget-Friendly</p>
                        </li>
                        <li>
                            <p>Responsive Design</p>
                        </li>
                        <li>
                            <p>SEO Focus</p>
                        </li>
                        <li>
                            <p>Personalized Support</p>
                        </li>
                    </ul>
                </div>
                <div className='middle-right'>
                    <h3>Hours</h3>
                    <hr />
                    <p style={{ marginTop: "1rem" }}>
                        Mon - Fri: <br />
                        8:00AM - 5:00PM
                    </p>
                    <p style={{ width: "110.19px" }}>
                        Sat - Sun <br />
                        Closed
                    </p>
                </div>
                <div className='right'>
                    <h3>Contact Us</h3>
                    <hr />
                    <a style={{
                        marginTop: "1rem"
                    }} href='tel:+19095015252'>+1 (909) 501-5252</a>
                    <a href='mailto:contact@qqstudios.net'>Send us an e-mail</a>
                </div>
            </div>
        </section>
    )
}

export default Footer