import './App.css';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Menu from './pages/Menu/Menu';
import OurStory from './pages/OurStory/OurStory'
import Services from './pages/Services/Services'
import OurWork from './pages/OurWork/OurWork'
import Contact from './pages/Contact/Contact'
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

function App() {
  const [fadeIn, setFadeIn] = useState({
    home: false,
    service: false
  })

  console.log(fadeIn)

  return (
    <div className="App">
      <Router>
        <Navbar setFadeIn={setFadeIn} />
        <Routes>
          <Route path='/' element={<Home fadeIn={fadeIn} setFadeIn={setFadeIn} />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/about' element={<OurStory />} />
          <Route path='/packages' element={<Services fadeIn={fadeIn} setFadeIn={setFadeIn} />} />
          <Route path='/work' element={<OurWork />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
