import React, { useState, useEffect } from 'react'
import './styles.css'

function Hero({ desktopMockup, statement, mainText, subText, paragraph, button }) {
    const [textShadow, setTextShadow] = useState({
        primaryTextX: 0,
        primaryTextY: 0,
        secondaryTextX: 0,
        secondaryTextY: 0
    })

    useEffect(() => {

        setTimeout(() => {
            setTextShadow({
                primaryTextX: -8,
                primaryTextY: -6,
                secondaryTextX: 8,
                secondaryTextY: 6
            })
        }, 500);

    }, [])


    return (
        <section className='Hero'>
            <div className='hero-wrapper'>
                <div className='hero-left'>
                    <h3>{statement}</h3>
                    <div className='title-overlay'>
                        <h1 style={{
                            textShadow: `${textShadow.primaryTextX}px ${textShadow.primaryTextY}px 0px #D78312`
                        }}>
                            {mainText} <br />
                            {subText}
                        </h1>
                        <h1 style={{
                            textShadow: `${textShadow.secondaryTextX}px ${textShadow.secondaryTextY}px 0px #0F2934`
                        }}>
                            {mainText} <br />
                            {subText}
                        </h1>
                    </div>
                    <p>
                        {paragraph}
                    </p>
                    <div className='btn-wrapper'>
                        <button>{button}</button>
                        <button>{button}</button>
                    </div>
                </div>
                <div className='hero-right'>
                    <img src={desktopMockup} alt='mockup website' />
                </div>
            </div>
        </section>
    )
}

export default Hero