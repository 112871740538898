import React, { useState } from 'react'
import { IoIosPlayCircle } from "react-icons/io";
import QQStudiosVideo from '../../../../assets/quirkAndQuillVideo.mp4'
import './styles.css'

const Video = () => {
    const [isPlaying, setPlaying] = useState(false);

    const playVideo = () => {
        setPlaying(true);
    };

    const handlePause = () => {
        setPlaying(false);
    };

    return (
        <div className='Video' style={{ height: isPlaying ? "auto" : "" }}>
            <div className='video-overlay'></div>
            {!isPlaying && <IoIosPlayCircle size={150} color='white' onClick={playVideo} />}
            {isPlaying && (
                <video controls autoPlay onEnded={handlePause} onPause={handlePause} width="100%">
                    <source src={QQStudiosVideo} type='video/mp4' />
                </video>
            )}
        </div>
    );
};

export default Video