import React from 'react'
import './styles.css'

function About() {
    return (
        <section className='About'>
            <div className='about-wrapper'>
                <div className='about-info'>
                    <h2>Web Design and Development <strong><u>Experts</u></strong></h2>
                    <h4>Elevate Your Business with Unique Design and SEO.</h4>
                    <hr />
                    <p>
                        Crafting a unique online presence is vital for the growth
                        of small businesses. Our approach combines distinctive
                        web design with strategic SEO, empowering small businesses
                        to thrive in the competitive digital landscape.
                    </p>
                </div>
                <div className='about-img'></div>
                <div className='services-img'></div>
                <div className='services-info'>
                    <h2>Advantages of Choosing Our <strong><u>Services</u></strong></h2>
                    <h4>At the core of each search is a person.</h4>
                    <hr />
                    <ul>
                        <li>
                            <p>
                                <strong>Tailored Solutions:</strong> Crafted specifically for small businesses, our websites are designed to meet your unique needs, ensuring a personalized online presence.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Local Expertise:</strong> As a local agency, we understand the dynamics of your community, enabling us to create websites that resonate with your target audience and enhance local visibility.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Client-Centric Approach:</strong> Our commitment to prioritizing your goals and preferences ensures that every aspect of your website aligns with your vision and objectives.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Innovative Design:</strong> Our creative team leverages the latest design trends and technologies to deliver visually appealing and modern websites that captivate your audience.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </section >
    )
}

export default About