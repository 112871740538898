import React from 'react'
import './styles.css'

function Team() {
    return (
        <section className='Team'>
            <div className='team-left'>
                <h2>Meet our <strong style={{ color: "#D78312" }}><u>Team</u></strong></h2>
                <h4>Discover the Faces Behind Our Expertise: Meet Our Dynamic Team</h4>
                <hr />
                <p>
                    We are a passionate team of designers, developers, and digital
                    marketing specialists, committed to guiding our clients on the path to success.
                    By leveraging thorough research, innovative ideas, and interactive design,
                    we empower organizations to create intuitive online experiences that drive
                    business growth
                </p>
            </div>
            <div className='team-right'></div>
        </section>
    )
}

export default Team