import React from 'react'
import reviewOne from '../../../../assets/review-one.png'
import './styles.css'

function Testimonials() {

    const redirectToYelp = () => {
        window.open('https://www.yelp.com/biz/quirk-and-quill-studios-loma-linda?osq=Quirk+%26+Quill+Studios', '_blank')
    }

    return (
        <div className='Testimonials'>
            <div className='testimonials-wrapper'>
                <div className='testimonial-left'>
                    <h2>See What People Have To <strong><u>Say</u></strong></h2>
                    <h4 style={{ color: "#0F2934" }}>Don't Forget to Leave a Review</h4>
                    <hr />
                    <div className='btn-wrapper' onClick={redirectToYelp}>
                        <button>Yelp</button>
                        <button>Yelp</button>
                    </div>
                </div>
                <div className='testimonial-right'>
                    <img src={reviewOne} width="715px" alt='reviews' />
                </div>
            </div>
        </div>
    )
}

export default Testimonials