import React, { useState, useEffect } from 'react';
import './styles.css';

function InfiniteCarousel({ carousel }) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        // Set up event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            className='InfiniteCarousel'
            style={{
                width: screenWidth > 800 ? `${carousel.length * 50}%` : `${carousel.length * 100}%`
            }}
        >
            {carousel.map((item, index) => (
                <div key={index} className='slider'>
                    <img src={item.img} alt='carousel' />
                </div>
            ))}
        </div>
    );
}

export default InfiniteCarousel;
