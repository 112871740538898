import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { GrMenu } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import './styles.css'

function Navbar({ setFadeIn }) {
    const [mobileMenu, setMobileMenu] = useState(false)

    const fadeHome = () => (
        setTimeout(() => {
            setFadeIn(prevState => ({
                ...prevState,
                home: true,
                ourStory: false,
                service: false,
                ourWork: false,
                contact: false
            }))
        }, 300),
        setMobileMenu(false)
    )

    const fadeStory = () => (
        setTimeout(() => {
            setFadeIn(prevState => ({
                ...prevState,
                home: false,
                ourStory: true,
                service: false,
                ourWork: false,
                contact: false
            }))
        }, 300),
        setMobileMenu(false)

    )

    const fadeService = () => (
        setTimeout(() => {
            setFadeIn(prevState => ({
                ...prevState,
                home: false,
                ourStory: false,
                service: true,
                ourWork: false,
                contact: false
            }))
        }, 300),
        setMobileMenu(false)

    )

    const fadeWork = () => (
        setTimeout(() => {
            setFadeIn(prevState => ({
                ...prevState,
                home: false,
                ourStory: false,
                service: false,
                ourWork: true,
                contact: false
            }))
        }, 300),
        setMobileMenu(false)
    )

    const fadeContact = () => (
        setTimeout(() => {
            setFadeIn(prevState => ({
                ...prevState,
                home: false,
                ourStory: false,
                service: false,
                ourWork: false,
                contact: true
            }))
        }, 300),
        setMobileMenu(false)
    )

    const handlemobileMenu = () => {
        setMobileMenu(true)
    }

    const handleClose = () => {
        setMobileMenu(false)
    }

    console.log(mobileMenu)

    return (
        <div className='Navbar'>
            <button className='mobile-button' onClick={handlemobileMenu}><GrMenu size={24} /></button>
            <nav className='desktop-nav'>
                <ul>
                    <li>
                        <Link onClick={fadeHome} to="/">Home</Link>
                    </li>
                    <li>
                        <Link onClick={fadeStory} to="/about">Our Story</Link>
                    </li>
                    <li>
                        <Link onClick={fadeService} to="/packages">Packages</Link>
                    </li>
                    <li>
                        <Link onClick={fadeWork} to="/work">Our Work</Link>
                    </li>
                    <li>
                        <Link onClick={fadeContact} to="/contact">Contact Us</Link>
                    </li>
                </ul>
            </nav>
            <nav className='mobile-nav' style={{ top: !mobileMenu ? "-1600px" : "0" }}>
                <button onClick={handleClose}><IoMdClose /></button>
                <ul>
                    <li>
                        <Link onClick={fadeHome} to="/">Home</Link>
                    </li>
                    <li>
                        <Link onClick={fadeStory} to="/about">Our Story</Link>
                    </li>
                    <li>
                        <Link onClick={fadeService} to="/packages">Packages</Link>
                    </li>
                    <li>
                        <Link onClick={fadeWork} to="/work">Our Work</Link>
                    </li>
                    <li>
                        <Link onClick={fadeContact} to="/contact">Contact Us</Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Navbar