import React, { useEffect } from 'react'
import Hero from '../../components/Hero/Hero';
import desktopMockup from '../../assets/service-mockup.png'
import Testimonials from '../Home/sections/Testimonials/Testimonials';
import Contact from '../../components/Contact/Contact';
import './styles.css'

function Services({ fadeIn, setFadeIn }) {

    useEffect(() => {
        setTimeout(() => {
            setFadeIn(prevState => ({
                ...prevState,
                service: true
            }));
        }, 300);
    }, [])

    return (
        <div className='Services' style={{ opacity: fadeIn.service ? "1" : "0" }}>
            <Hero mainText="VIEW OUR" subText="PRICES" desktopMockup={desktopMockup} />
            <section className='service-wrapper'>
                <h4>PACKAGES</h4>
                <h2>Choose Your <strong><u>Package</u></strong></h2>
                <div className='packages'>
                    <div className='single-page-application'>
                        <h4>SPA</h4>
                        <p>We will design and create a <strong><u>CUSTOM</u></strong> single page application <strong><u>NOT</u></strong> based off of a template.</p>
                        <h3>USD $1,000</h3>
                        <ul>
                            <li>Custom Single Page Application</li>
                            <li>1 Single Page Application</li>
                            <li>Custom Mockup</li>
                            <li>Basic SEO Content</li>
                            <li>Mobile Responsive</li>
                            <li>Contact Form</li>
                            <li>Photos</li>
                            <li>Testimonial Integration</li>
                            <li>Google Maps Integration</li>
                        </ul>
                        <span>*Licenses and Domain Registration Not Included</span>
                    </div>
                    <div className='template'>
                        <h4>Template</h4>
                        <p>We will create a 1-5 page brochure style website based off of a <strong><u>TEMPLATE</u></strong>.</p>
                        <h3>USD $1,500</h3>
                        <ul>
                            <li>Website Template</li>
                            <li>1-5 Pages</li>
                            <li>No Custom Mockup</li>
                            <li>Basic SEO Content</li>
                            <li>Mobile Responsive</li>
                            <li>Contact Form</li>
                            <li>Photos</li>
                            <li>Testimonial Integration</li>
                            <li>Google Maps Integration</li>
                        </ul>
                        <span>*Licenses and Domain Registration Not Included</span>
                    </div>
                    <div className='custom'>
                        <h4>Custom</h4>
                        <p>We will design and create a <strong><u>CUSTOM</u></strong> website, <strong><u>NOT</u></strong> based off of a template.</p>
                        <h3>USD $2,500</h3>
                        <ul>
                            <li>Custom Website</li>
                            <li>1-5 Pages</li>
                            <li>3-4 Custom Page Mockups</li>
                            <li>Basic SEO Content</li>
                            <li>Mobile Responsive</li>
                            <li>Contact Form</li>
                            <li>Photos</li>
                            <li>Testimonial Integration</li>
                            <li>Google Maps Integration</li>
                        </ul>
                        <span>*Licenses and Domain Registration Not Included</span>
                    </div>
                    <div className='e-commerce'>
                        <h4>E-Commerce</h4>
                        <p>We will design and create a <strong><u>CUSTOM</u></strong> e-commerce website, <strong><u>NOT</u></strong> based off of a template.</p>
                        <h3>USD $2,500</h3>
                        <ul>
                            <li>Same As Custom</li>
                            <li>Install And Configure WooCommerce</li>
                            <li>$8 Per Item</li>
                            <li>Set Up Shipping</li>
                            <li>WooCommerce Analytics</li>
                            <li>Contact Form</li>
                            <li>Photos</li>
                            <li>Testimonial Integration</li>
                            <li>Google Maps Integration</li>
                        </ul>
                        <span>*Licenses and Domain Registration Not Included</span>
                    </div>
                    <div className='enterprise'>
                        <h4>Enterprise</h4>
                        <p>We will create an <strong><u>ENTERPRICE</u></strong> level website from scratch.</p>
                        <h3>USD $5,000</h3>
                        <ul>
                            <li>Custom Website</li>
                            <li>10+ Pages</li>
                            <li>5-10 Custom Page Mockups</li>
                            <li>Basic SEO Content</li>
                            <li>Mobile Responsive</li>
                            <li>Contact Form</li>
                            <li>Photos</li>
                            <li>Testimonial Integration</li>
                            <li>Google Maps Integration</li>
                        </ul>
                        <span>*Licenses and Domain Registration Not Included</span>
                    </div>
                </div>
            </section>
            <section>
                <Testimonials />
            </section>
            <section>
                <Contact />
            </section>
        </div>
    )
}

export default Services